<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2023-12-20 09:44:19
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2023-12-22 15:02:05
 * @FilePath: \visions\src\views\zhyl\smartPublicGuard\healthTestRun\healthReport\tab2\tab2.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab">
    <div class="box">
      <div class="title">
        <span>B超异常</span>
      </div>
      <div class="chart" id="echart"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {},
  data () {
    return {}
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.$nextTick(() => {
      this.canvas()
    })
    window.addEventListener('resize', this.getresize)
  },
  methods: {
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) { // 表格没数据不做处理
        return title.length * 30 + 'px'
      }
      let flexWidth = 0// 初始化表格列宽
      let columnContent = ''// 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '14px Microsoft YaHei'
      if ((prop === '') && title) { // 标题长内容少的，取标题的值,
        columnContent = title
      } else { // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七个字'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 60 + num
      return flexWidth + 'px'
    },
    getresize () {
      setTimeout(() => {
        this.myChart.resize()
      }, 500)
    },
    canvas () {
      if (!document.getElementById('echart')) return
      var chartDom = document.getElementById('echart')
      this.myChart = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '合计：6875人',
          subtext: '占体检总人数的39.85%',
          left: 'center',
          top: '45%',
          textStyle: {
            fontSize: 30,
            color: '#454c5c',
            align: 'center'
          },
          subtextStyle: {
            fontSize: 16,
            color: '#6c7a89'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          textStyle: {
            fontSize: 16
          },
          padding: [20, 20, 20, 20],
          formatter: (name) => {
            var data = option.series[0].data
            var tarValue
            for (var i = 0, l = data.length; i < l; i++) {
              if (data[i].name === name) {
                tarValue = data[i].value
              }
            }
            return `${name} (${tarValue}人)     `
          }
        },
        series: [
          {
            name: 'B超异常',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: '肝囊肿' },
              { value: 735, name: '脂肪肝' },
              { value: 580, name: '胆结石' },
              { value: 484, name: '其他异常' }
            ]
          }
        ]
      }
      option && this.myChart.setOption(option)
    },
    handleSelect (key) {
      this.activeIndex = key
    },
    openTest () {
      this.showTest = true
    },
    closeTest () {
      this.showTest = false
    },
    openDetection () {
      this.showDetection = true
    },
    closeDetection () {
      this.showDetection = false
    },
    openTestDetection () {
      this.showTestDetection = true
    },
    closeTestDetection () {
      this.showTestDetection = false
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;

  .box {
    width: 100%;
    height: 100%;
    padding: 20px;
    border: 1px solid #EBEEF5;
    box-sizing: border-box;

    .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 24px;
    }

    .chart {
      width: 100%;
      height: calc(100% - 40px);
    }
  }
}
</style>
